<template>
    <card :orientation="'horizontal'">
        <div class="k-vbox">
            <cardHeader>
                <cardTitle style="float:left">
                    {{
          this.document.description
                    }}
                </cardTitle>
            </cardHeader>
            <iframe v-if="this.document && this.document.isEnote"
                    :srcdoc="'<div style=\' width: fit-content; margin: 0 auto;\'>' + this.document.html + '</div>'"
                    @load="loadSignatures"
                    class="docIframe">
            </iframe>
        </div>
    </card>
</template>
<script>
import {
        Card,
        CardHeader,
        CardBody,
        CardTitle,
        CardImage,
        CardActions,
} from "@progress/kendo-vue-layout";
import ApiService from "@/core/services/api-service";
import PDFForm from "@/core/services/pdf-form";
import { PDFDocument, StandardFonts, rgb, degrees } from 'pdf-lib'
import { Button } from "@progress/kendo-vue-buttons";
import { useRoute } from "vue-router";
import { loadFileUrl } from "@/core/services/api-service";
import GridDataHelper from "@/core/services/grid-data-helper";
import moment from "moment";

let IS_SCROLLING = false;

export default {
        components: {
            card: Card,
            cardHeader: CardHeader,
            cardBody: CardBody,
            cardTitle: CardTitle,
            cardImage: CardImage,
            cardActions: CardActions,
            kbutton: Button,
        },
        computed: {
            moment: () => moment,
            docPath: function () {
                var path = this.document.docURL;
                if (this.document.extension !== undefined || this.document.extension != "") {
                    if (!path.includes(this.document.extension)) path += this.document.extension;
                }
                console.log("Path", path, this.document.extension);
                return this.host + path;
            },
        },
        props: ["documentId"],
        watch: {

        },
        data() {
            return {
                document: "",
                host: loadFileUrl,
                pageCount: 1,
                curPage: 1,
                zoom: 100,
                file: null,
            };
        },
        async created() {
            // const route = useRoute();
            //let response = await ApiService.GenerateGetRequest(
            //    `package/document/${this.$route.params.id}/tags`
            //);
            let response2 = await ApiService.GenerateGetRequest(
                `package/document/${this.$route.params.id}`
            );
            let document = response2.content;
            //document.smartTags = response.content.smartTags;
            if (response2.isSuccess) {
                this.document = document;
                console.log("Document", this.document);
                this.loadPDF(this.document);
            }
        },
        methods: {
            formatStatus: function (status) {
                return GridDataHelper.normalizeStatus(status);
            },
            formatDate: function (date) {
                return moment(date).format("MM/DD/YYYY hh:mm A");
            },
            loadSignatures: async function () {
                if (this.document != "" && this.document.isEnote) {

                    this.document.smartTags.forEach((tag) => {
                        var iframe = $(".docIframe");
                        var imgSrc = this.host + encodeURIComponent(tag.imgUrl);
                        function updateImageContainer() {
                            var element = $(
                                `SIGNATURE_LINE[_ID='${tag.controlId}']`,
                                iframe.contents()
                            );
                            element.css({
                                background: "none",
                                border: "none",
                            });

                            // signatures not lining up with eNote fix
                            let marginLeft = $(element).closest("tr").css('margin-left');
                            if (marginLeft == "0" || marginLeft == "0px")
                                marginLeft = $(element).closest("p").css('text-indent');

                            
                            marginLeft = ".5in";

                            element.html(
                                `<img style="height:20%; width:40%; margin-left: ${marginLeft};" src =${imgSrc} /> `
                            );
                        }
                        // eNote name fix
                        var name = $(
                            `[id^='Borrower'][id$='FirstName'], [id^='Borrower'][id$='MiddleName'], [id^='Borrower'][id$='LastName']`,
                            iframe.contents()
                        );
                        if (name.length > 0)
                            name.each((i, item) => {
                                let txt = $(item).text();
                                if (txt.trim() === "")
                                    txt = " "
                                else
                                    txt = ' ' + txt.trim() + ' '
                                $(item).text(txt);
                            });
                        if (tag.isTag) {
                            if (this.document.status == "Signed") {
                                var element = $(
                                    `SIGNATURE_IMAGE[_ID='${tag.controlId}']`,
                                    iframe.contents()
                                );
                                if (element.length == 0) {
                                    updateImageContainer();
                                    return;
                                }
                                

                                // signatures not lining up with eNote fix
                                let marginLeft = $(element).closest("tr").css('margin-left');
                                if (marginLeft == "0" || marginLeft == "0px")
                                    marginLeft = $(element).closest("p").css('text-indent');

                                marginLeft = ".5in";

                                element.html(
                                    `<img style="height:20%; width:40%; margin-left: ${marginLeft};" src =${imgSrc} /> `
                                );
                            } else {
                                updateImageContainer();
                            }
                           
                        }
                    });
                }
            },
            loadPDF(document) {
                let self = this;
                this.$nextTick(async () => {
                    var pdfForm = new PDFForm();
                    var files = [];
                    files.push({
                        documentID: document.documentID,
                        documentId: document.documentID,
                        docURL: document.docURL,
                        html: document.html,
                        documentStatus: document.status,
                        isEnote: document.isEnote,
                        signerId: document.signerId,
                        wetSigned: document.wetSigned,
                        documentType: document.documentType, //"Signing" => "Review_And_Sign", "Reviewing" => "Review_Only",
                        participantStatus: document.participantStatus,
                        smartTags: [],
                        fieldLinks: [],
                        availableForPresign: false,
                        path: ApiService.EnvironmentURL() + "document/load?path=" + document.documentUrl,
                        noSpaceName: "_" + document.description.replaceAll(" ", "").replaceAll(/[^\w\s]/gi, '_'),
                        name: document.description,
                        description: document.description,
                        pages: document.noOfPages,
                    });
                    self.file = files[0];
                    $('.previewer > div.content').html('');
                    var pdf = await PDFJS.getDocument(files[0].path);
                    var page = await pdf.getPage(1);
                    var scale = 1.35;
                    var viewport = page.getViewport(scale);
                    // Support HiDPI-screens.
                    var outputScale = window.devicePixelRatio || 1;
                    var width = Math.floor(viewport.width * outputScale);
                    var height = Math.floor(viewport.height * outputScale);
                    self.pageCount = pdf.numPages;

                    pdfForm.loadMulitplePDFs(document.signerId, document.signerId, files, 0, '.previewer > div.content', width, height, true, false, (page, currentObject, index) => {
                        if (page == 1)
                            $("div.previewer > div.content > div:eq(" + (page - 1) + ")").css("margin", "60px auto 10px auto");
                        else
                            $("div.previewer > div.content > div:eq(" + (page - 1) + ")").css("margin", "10px auto");
                    });
                    setInterval(() => {
                        if (IS_SCROLLING) {
                            IS_SCROLLING = false;
                            this.onScrollHandler();
                        }
                    }, 300);
                });
            },
            async checkItemsInView(event) {
                IS_SCROLLING = true;
            },
            onZoomOut() {
                if (this.zoom > 10)
                    this.zoom -= 10;
                $('.previewer > div.content').css('transform', 'scale(' + this.zoom / 100 + ')');
            },
            onZoomIn() {
                if (this.zoom < 200)
                    this.zoom += 10;
                $('.previewer > div.content').css('transform', 'scale(' + this.zoom / 100 + ')');
            },
            async onScrollHandler() {
                var self = this;
                var container = $(".previewer > div.content");
                function checkInView(container, elem, partial) {
                    let eTop = elem.offsetTop;
                    let scrollPos = $(container).parent().scrollTop();
                    return (eTop >= scrollPos)
                }

                // will only ever have at least two pieces of page show on the ui
                let array = container.find("div[data-page]").get();

                // new code to determine if we are entering new page
                let firstItemInView = null;
                for (let i = 0; i < array.length; i++) {
                    let e = array[i];
                    let isInView = checkInView(container[0], e, true);
                    if (isInView) {
                        firstItemInView = e;
                        break;
                    }
                }
                if (firstItemInView)
                    self.curPage = $(firstItemInView).attr("data-page");
            },
            onDownload() {
                self = this;
                fetch(self.file.path)
                    .then((res) => {
                        return res.blob();
                    })
                    .then((data) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(data);
                        var docName = self.file.description;
                        if (docName.lastIndexOf('.xml') > -1)
                            docName = docName.substr(0, docName.lastIndexOf('.xml'));
                        a.download = docName;
                        a.click();
                    });
            },
            onPrint() {
                var newWin = window.frames['printf'];
                newWin.document.write(
                    `<html>
                                    <title>${this.file.description}</title>
                                    <style type="text/css" media="print">
                                        @page
                                        {
                                            size: auto;   /* auto is the initial value */
                                            margin: 0mm;  /* this affects the margin in the printer settings */
                                        }
                                        .pagebreak {
                                            clear: both;
                                            page-break-after: always;
                                        }
                                    </style>
                                    <body onload=window.print() > ` + $(".previewer > div.content").html() + `
                                    </body>
                                </html>`);
                $('div', $(newWin.document)).addClass('pagebreak');
                var newEntries = $('div > canvas', $(newWin.document));
                var oldEntries = $('div > canvas', $(".previewer > div.content"));
                for (var i = 0; i < newEntries.length; i++) {
                    //grab the context from your destination canvas
                    var destCtx = newEntries[i].getContext('2d');

                    //call its drawImage() function passing it the source canvas directly
                    destCtx.drawImage(oldEntries[i], 0, 0);
                }

                newWin.document.close();
            }
        },
};
</script>
<style scoped>

    .docIframe {
        width: 100%;
        height: 80vh;
    }

    .k-vbox {
        width: 100%;
    }

    .header-items {
        float: right;
    }

    .previewer {
        background: #33363b;
        padding: 10px 0;
        overflow-x: hidden;
    }

    .preview-toolbar {
        width: 100%;
        height: 60px;
        background: #2a2929;
        margin: -10px 0 0 0;
        position: absolute;
        z-index: 1;
        display: flex;
    }

        .preview-toolbar .center {
            margin: 20px auto;
        }

        .preview-toolbar .pagenum {
            align-items: center;
            display: flex;
            overflow: hidden;
            float: left;
            color: #ebebeb;
            margin: 2px 0;
        }

        .preview-toolbar .zoom {
            align-items: center;
            display: flex;
            overflow: hidden;
            float: left;
            margin: 0 25px;
        }

            .preview-toolbar .zoom .percent {
                float: left;
                width: 60px;
                height: 27px;
                background: #000;
                color: #ebebeb;
                line-height: 27px;
                text-align: center;
            }

        .preview-toolbar button {
            align-items: center;
            display: flex;
            overflow: hidden;
        }

        .preview-toolbar input {
            align-items: center;
            display: flex;
            overflow: hidden;
        }

        .preview-toolbar .download {
            align-items: center;
            display: flex;
            overflow: hidden;
            float: left;
            margin: 0;
            color: #ebebeb;
            cursor: pointer;
            padding: 5px;
        }

            .preview-toolbar .download:hover {
                background: #4a4a4a;
                padding: 5px;
            }

        .preview-toolbar .print {
            align-items: center;
            display: flex;
            overflow: hidden;
            float: left;
            margin: 0 0 0 10px;
            color: #ebebeb;
            cursor: pointer;
            padding: 5px;
        }

            .preview-toolbar .print:hover {
                background: #4a4a4a;
                padding: 5px;
            }

    div.previewer > div.content > div {
        box-shadow: 0px 3px 5px 2px rgba(0,0,0,1);
    }
</style>
