<template>
    <div class="row">
        <div class="col-sm-offset-10 col-sm-2">
            <button v-if="!hiddenActions.includes('acknowledgeUpload')" :disabled="documentCount == 0" title="Uploading Complete" class="k-button k-primary k-add-button upload_complete" @click='submitUploadedDocuments'>
                Uploading Complete
            </button>
        </div>
    </div>
    <kendo-datasource ref="documentsource"
                      :transport-read="readData"
                      :transport-update="updateData"
                      :transport-create="createData"
                      :transport-destroy="deleteData"
                      :schema-model-id="'documentID'"
                      :schema-model-fields="schema"
                      :schema-data="'data'"
                      :schema-total="'total'"
                      :schema-groups="'groups'"
                      :schema-aggregates="'aggregates'"
                      :schema-errors="'errors'"
                      :transport-parameter-map="parameterMap"
                      :server-filtering="true"
                      :server-paging="true"
                      :batch='true'
                      :page-size='100'>
    </kendo-datasource>

    <kendo-grid :data-source-ref="'documentsource'"
                ref="kendoJqueryGrid"
                :class="'documents'"
                :pageable="true"
                :edit="onEdit"
                :editable="packagePopUpTemplate"
                :cancel="onCancel"
                :sortable="true"
                :resizable="true"
                :pageable-refresh="true"
                :pageable-button-count="5"
                :pageable-page-sizes="[5, 10, 20, 100]"
                :pageable-always-visible="true"
                :navigatable="true"
                selected-field="selected"
                :filterable="shouldFilter"
                :pdf-all-pages="true"
                :pdf-avoid-links="true"
                :pdf-paper-size="'A4'"
                :pdf-margin="{ top: '2cm', left: '1cm', right: '1cm', bottom: '1cm' }"
                :pdf-landscape="true"
                :pdf-repeat-headers="true"
                :pdf-scale="0.8"
                :columns="columns"
                @databound="documentDataBound"
                @filterchange="filterChange"
                @selectionchange="onSelectionChange"
                @headerselectionchange="onHeaderSelectionChange">
        <toolbar v-show="!hideCol">
            <button v-if="!hiddenActions.includes('add')" title="Add New" class="k-primary k-button k-add-button" @click='documentInsert'>
                {{this.addText}}
            </button>
            <button v-if="!hiddenActions.includes('downloadAll')" title="Download All" class="k-button k-primary k-add-button" @click='documentDownloadAll'>
                Download All
            </button>
            <button v-if="!hiddenActions.includes('downloadAllWet')" title="Download All Wet Sign" class="k-button k-primary k-add-button" @click='documentDownloadAllWetSign'>
                Download All Wet Sign
            </button>
            <button v-if="!hiddenActions.includes('downloadAllUploaded')" title="Download All Wet Sign" class="k-button k-primary k-add-button" @click='documentUploaded'>
                Download All Uploaded
            </button>
            <div style="margin: 0 0px 0 auto;" v-if="canAdd">
                <button title="Export to Excel" class="k-button k-primary mr-1" @click="exportExcel">
                    Export to Excel
                </button>
                <button class="k-button k-primary" @click="exportPDF">
                    Export to PDF
                </button>
            </div>
        </toolbar>
        <!--<kendo-grid-column :field="'documentID'" :title="'ID#'" :width="100"></kendo-grid-column>
        <kendo-grid-column :field="'description'" :title="'Document Name'" :width="245"></kendo-grid-column>
        <kendo-grid-column :field="'description'" :title="'Description'" :width="330"></kendo-grid-column>
        <kendo-grid-column :field="'pages'" :title="'Pages'" :width="160"></kendo-grid-column>
        <kendo-grid-column :field="'signingStatus'" :title="'Status'" :width="150" :template="statusTemplate"></kendo-grid-column>
        <kendo-grid-column :command="[
                           { className: 'participant-cmd' , text: 'Download' , click: documentDownload, visible: function (e) { return true; } },
                           { className: 'participant-cmd' , text: 'View' , click: documentView, visible: $parent.canViewDocument },
            { className: 'participant-cmd', text: 'Edit', click: documentEdit, visible: function (e) { return true; } },
            { className: 'participant-cmd', text: 'Delete', click: documentDelete, visible: function (e) { return true; } }]"
            :title="'&nbsp;'" :width="280">
        </kendo-grid-column>-->
    </kendo-grid>
    <pdfexport ref="gridPdfExport" v-show="false">
        <native-grid :columns="exportColumns" />
    </pdfexport>
</template>
<style scoped>
    * {
        font-weight: 400;
    }

    /deep/ .participant-cmd, /deep/ .participant-cmd:hover, /deep/ .participant-cmd::before {
        box-shadow: none;
        -webkit-box-shadow: none;
        text-transform: unset;
        background: initial;
        border: none;
        border-radius: unset;
        transition: none;
        -webkit-transition: none;
        padding: 8px 5px;
        font: 900 .94em 'Lato', sans-serif;
        color: #4d97ac;
    }

    .mr-1{
        margin-right:10px;
    }

    .test{
        width:100%;
    }       

    .upload_complete{
        background: #387a38;
        margin-bottom: 10px;
        float: right;
    }

    .status {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        color: white;
        font-weight: 900;
    }

    .status /deep/ span {
        display: block;
        padding: 10px;
        text-align: center;
        width: 100%;
    }

    .status.ready_for_review /deep/ span {
        background: #387a38;
    }

    .status.ready_for_signing /deep/ span {
        background: #4cb64c;
    }

    .status.default /deep/ span {
        background: #f3a638;
    }

    .status.uploaded /deep/ span {
        background: #33b3ab;
    }

    .status.reviewed /deep/ span, .status.signed /deep/ span, .status.notarized /deep/ span {
        background: #54b7d3;
    }

    .status.archived /deep/ span {
        background: #E3D4D4;
    }

    .status.cancelled /deep/ span, .status.wet_sign /deep/ span, .status.opted_out /deep/ span {
        background: slategray;
    }
</style>
<script>
    import ApiService from "../../core/services/api-service";
    import { GridToolbar, Grid as NativeGrid } from '@progress/kendo-vue-grid';
    import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
    import { GridPdfExport } from '@progress/kendo-vue-pdf';
    import { saveExcel } from '@progress/kendo-vue-excel-export';
    import { process } from '@progress/kendo-data-query';
    import { loadFileUrl } from "@/core/services/api-service";
    import { downloadFileUrl } from "@/core/services/api-service";
    import KendoPopupGenerator from '@/core/services/kendo-grid-popup-form-generator.js';
    import GridDataHelper from "../../core/services/grid-data-helper";
    import Swal from 'sweetalert2';
    import PermissionHelper from "../../core/permission-helper";

    const DOC_SETTINGS = {
        VIEW: "Document_CanViewDocumentDetail",
        ADD: "Document_CanAddDocument",
        EDIT: "Document_CanEditDocumentDetail",
        DELETE: "Document_CanDeleteDocument",
        ASSIGN_PARTICIPANT: "Document_CanEditDocumentParticipant",
    }

    const PREFIX = "Loan_";

    export default {
        name: 'PackageDocuments',
        props: ['packageide', 'ntype','fetchUrl', 'fetchFilter', 'hiddenCols', 'hiddenActions', 'addText'],
        components: {
            'kendo-grid': Grid,
            'kendo-grid-column': GridColumn,
            'kendo-datasource': KendoDataSource,
            'pdfexport': GridPdfExport,
            'toolbar': GridToolbar,
            'dropdownlist': DropDownList,
            'native-grid': NativeGrid,
        },
        async created() {
            this.permissions = PermissionHelper.GetPermissions(PREFIX);
            var role = this.$store.state.Admin.loginInformation.role;
            var settings = await ApiService.GenerateGetRequest("Admin/LenderSettings");
            if (settings != null && settings.isSuccess) {
                this.lenderSettings = settings.content;
                if (role != "Notary" && this.lenderSettings != undefined && this.lenderSettings != null && !this.lenderSettings.enableWetsign) {                  
                    this.$refs.kendoJqueryGrid.kendoWidget().hideColumn("wetSigned");
                }
            }
        },
        data() {
            ///****************************************************************************** NOTE ******************************************************************************///
            ///This is the ONLY WAY right now to include a function to specify if a column can be visible. All other ways fail due to a bug in kendo that attempts to add a _ctx ///
            ///to variables in the function thereby breaking the generated template. The workaround is to have an inline function so that kendo cannot access the function scope ///
            ///******************************************************************************************************************************************************************///
            function canViewDocument(e) {
                //if (e.isNew()) {
                //    return true;
                //}
                //var perm = e.permission.access.find(perm => perm.permission == 'ViewDocument');
                //if (perm) {
                //    return perm.hasAccess;
                //}
                return true;
            }

            return {
                hideCol: false,
                shouldFilter: false,
                schema: {
                    documentID: { editable: true },
                    packageID: { type: 'int', editable: true },
                    description: { type: 'string', editable: true },
                    noOfPages: { type: 'int', editable: true },
                    status: { type: 'string', editable: true },
                    docURL: { type: 'string', editable: true },
                    statusDescription: { type: 'string', editable: true },
                    permission: { type: 'object', editable: true },
                    wetSigned: { type: 'bool', editable: true },
                    uploaderName: {type: 'string', editable: true}
                },
                columns: [
                    { field: "documentID", title: "ID#", width: "100px", hidden: true },
                    { field: "description", title: "Document Name", width: "250px", template: this.docNameTemplate },
                    { field: "noOfPages", title: "Pages", width: "80px", hidden: this.hiddenCols.includes("pages") },
                    { field: "status", title: "Status", width: "200px", template: this.statusTemplate},
                    { field: "documentType", title: "Signing Type", width: "200px", template: this.normalizeDocumentType, hidden: this.hiddenCols.includes("documentType") },
                    { field: "availableForPresign", title: "PreSign", width: "100px", template: this.preSignTemplate, hidden: this.hiddenCols.includes("availableForPresign") },
                    { field: "wetSigned", title: "Wet Sign", width: "100px", template: this.wetSignedTemplate, hidden: this.hiddenCols.includes("wetSigned") },
                    { field: "uploaderName", title: "Uploaded By", width: "100px", hidden: this.hiddenCols.includes("uploaderName") },
                    {
                        title: '', width: "350px", command: [
                            { className: 'participant-cmd', text: 'Edit', click: this.documentEdit, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'Delete', click: this.documentDelete, visible: function (e) { return true; } },
                            { className: 'participant-cmd', text: 'View', click: (e) => { this.documentView(e) }, visible: canViewDocument },
                            { className: 'participant-cmd', text: 'Download', click: (e) => { this.documentDownload(e) }, visible: function (e) { return true; } },
                        ]
                    },

                ],

                exportColumns: [
                    { field: "documentID", title: "ID#", width: "100px", hidden: true },
                    { field: "description", title: "Document Name", width: "250px", template: this.docNameTemplate },
                    { field: "noOfPages", title: "Pages", width: "100px" },
                    { field: "status", title: "Status", width: "200px", template: this.statusTemplate},
                    { field: "documentType", title: "Signing Type", width: "200px", template: this.normalizeDocumentType, hidden: this.hiddenCols.includes("documentType") },
                    { field: "availableForPresign", title: "PreSign", width: "100px", template: this.preSignTemplate, hidden: this.hiddenCols.includes("availableForPresign") },
                    { field: "wetSigned", title: "Wet Sign", width: "100px", template: this.wetSignedTemplate, hidden: this.hiddenCols.includes("wetSigned") },
                    { field: "uploaderName", title: "Uploaded By", width: "100px", hidden: this.hiddenCols.includes("uploaderName") },
                ],
                newid: "",
                newdoc: {},
                permissions: {},

                // variables to see which participants to remove or add to document
                existingParticipantsForDocument: [],
                participantsToAddForDocuments: [],
                participantsToRemoveForDOcuments: [],
                lenderSettings: [],
                documentCount:0
            }
        },
        computed: {
            packagePopUpTemplate(e) {

                let html = "";
                html += "<div id='DocumentsGrid'>";
                html += !this.hiddenActions.includes("assignParticipants") ? "<div class='col-md-6'>" : "<div class='col-md-12'>";
                html += KendoPopupGenerator.generatePopUpFileFormField('Document', 'Document', 'CreateDocumentGridFile');
                html += KendoPopupGenerator.generatePopUpFormFields('Description', 'Document Name', 'description');

                html += "   </div>";
                if(!this.hiddenActions.includes("assignParticipants")){
                html += `   <div class='right-grid col-md-6'>
                                <h3>Assign Participants</h3>
                                <ul class="list-group">`

                html += `        </ul>
                            </div>`;
                }
                html += "</div>";


                return { 'template': html, 'mode': 'popup' }
            },
            canAdd: function () { return this.permissions[DOC_SETTINGS.ADD] },
            canEdit: function () { return this.permissions[DOC_SETTINGS.EDIT] && !this.hiddenActions.includes('edit') },
            canDelete: function () { return this.permissions[DOC_SETTINGS.DELETE] },
            canViewDetails: function () { return this.permissions[DOC_SETTINGS.VIEW] && !this.hiddenActions.includes('view')},
            canAssign: function () { return this.permissions[DOC_SETTINGS.ASSIGN_PARTICIPANT] },
        },
        methods: {
            reloadParent: function () {
                this.$emit('reload-parent');
            },
            wetSignedTemplate: function (e) {
                let html = "";
                var disabled = e.availableForPresign || e.isEnote || e.loanPackageStatus != "In_Progress" || (!e.statusDescription.includes("Ready") && e.statusDescription != "Uploaded" && e.statusDescription != "Reviewed" && e.statusDescription != "Wet_Sign") ? "disabled" : "";
                if (e.wetSigned) html = `<input id='wetSignCheckbox' class='wetSignCheckbox' type='checkbox' checked='true' ` + disabled + `/>`;
                else html = `<input id='wetSignCheckbox' class='wetSignCheckbox' type='checkbox' ` + disabled + `/>`;
                return html;
            },
            preSignTemplate: function (e) {
                let html = "";
                var disabled = e.wetSigned || e.isEnote || e.loanPackageStatus != "In_Progress" || (!e.statusDescription.includes("Ready") && e.statusDescription != "Uploaded" && e.statusDescription != "Reviewed" && e.statusDescription != "Wet_Sign") ? "disabled" : "";
                if (e.availableForPresign) html = `<input id='preSignCheckbox' class='preSignCheckbox' type='checkbox' checked='true' ` + disabled + `/>`;
                else html = `<input id='preSignCheckbox' class='preSignCheckbox' type='checkbox' ` + disabled + `/>`;
                return html;               
            },
            docNameTemplate: function (e) {
                let html = ``;
                if (this.canViewDetails) html = `<a href="#" data-id="${e.documentID}" class="link package-document-name">${e.description}</a>`
                else html = `<span>${e.description}</span>`
                return html;
            },
            async checkboxClicked(e, isChecked) {    
                if (!this.canEdit) return;

                var self = this;

                var user = this.$store.state.Admin.loginInformation;
                var data = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));              
                var wetSign = data["wetSigned"];
                var preSign = data["availableForPresign"];

                var text = "";
                if (e.target.id == 'preSignCheckbox') {
                    preSign = isChecked;
                    text = 'presign';
                }
                else {
                    wetSign = isChecked;
                    text = 'wetsign'
                }
                
                var payload = {
                    'wetSign': wetSign,
                    'reviewRequired': data["isReview"],
                    'availableForPreSign': preSign,
                    'documentId': parseInt(data["id"]),
                    'adminUserId': user.userId
                }

                await ApiService.GeneratePostRequest(`package/document/details/update`, payload).then((response) => {
                    if (response.isSuccess) {
                        Swal.fire({
                            title: 'Saved!',
                            text: text + ' updated',
                            icon: 'success',
                            confirmButtonText: 'Ok'
                        });
                        self.reloadParent();
                        self.reloadGrid();
                    }
                    else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Cannot remove ' + text,
                            icon: 'error',
                            confirmButtonText: 'Ok'
                        });

                    }
                });
            },
            documentInsert(event) {
                if (!this.canAdd) return;
                event.preventDefault();
                this.$refs.kendoJqueryGrid.kendoWidget().addRow();

                $("#Description")[0].required = false;
                $("#CreateDocumentGridFile").change(function () {
                    let name = $("#CreateDocumentGridFile")[0].files[0].name;

                    if (name == null) {
                        $("#Description")[0].value = "";
                    } else {
                        $("#Description")[0].value = name;
                    }
                });
            },
            documentEdit(event) {
                if (!this.canEdit) return;
                event.preventDefault();
                this.$refs.kendoJqueryGrid.kendoWidget().editRow($(event.target).closest("tr"));
            },
            onCancel(event) {
                if (!event.model.isNew()) {
                    event.preventDefault();
                }
                this.$refs.kendoJqueryGrid.kendoWidget().refresh();
            },
            documentDelete(event) {
                if (!this.canDelete) return;
                event.preventDefault();
                this.$refs.kendoJqueryGrid.kendoWidget().removeRow($(event.target).closest("tr"));
            },
            documentView(e) {
                var gridRow = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                console.log("gridRow: ", gridRow);
                //var watermark = "";
                //if (gridRow.wetSigned) {
                //    watermark = this.lenderSettings.wetSignWatermark;
                //}
                //if (gridRow.status == "Notarized") {
                //    watermark = this.lenderSettings.notarizeWatermark;
                //}
                //if (gridRow.isEnote) {
                //    watermark = this.lenderSettings.eSignDocWatermark;
                //}

                //const url = loadFileUrl + gridRow.docURL + "&watermark=" + watermark;
                var queryParam = "";
                if (gridRow.loanPackageStatus === "Completed" || gridRow.loanPackageStatus === "Closed")
                    queryParam = "&i=" + gridRow.documentID;

                const url = loadFileUrl + gridRow.docURL + queryParam;

                if (gridRow.isEnote) {
                    let route = this.$router.resolve({
                        path: "/admin/enote/" + gridRow.documentID
                    });
                    window.open(route.href, "_blank");
                    return;
                }

                console.log("loadFileURL: ", url);
                window.open(url, "_blank");
            },
            documentDownload(e) {
                e.preventDefault();
                var gridRow = this.$refs.kendoJqueryGrid.kendoWidget().dataItem($(e.target).closest("tr"));
                console.log("gridRow: ", gridRow);
                //var watermark = "";
                //if (gridRow.wetSigned) {
                //    watermark = this.lenderSettings.wetSignWatermark;
                //}
                //if (gridRow.status == "Notarized") {
                //    watermark = this.lenderSettings.notarizeWatermark;
                //}
                //if (gridRow.isEnote) {
                //    watermark = this.lenderSettings.eSignDocWatermark;
                //}
                var queryParam = "";
                if (gridRow.loanPackageStatus === "Completed" || gridRow.loanPackageStatus === "Closed")
                    queryParam = "&i=" + gridRow.documentID;

                this.docURL = gridRow.docURL;
                //const doc = downloadFileUrl + this.docURL + "&watermark=" + watermark + "&name=" + gridRow.description;
                const doc = downloadFileUrl + this.docURL + "&name=" + gridRow.description + queryParam;

                console.log("downloadFileURL: ", doc);

                const blob = new Blob([doc], { type: 'application/octet-stream' });
                const link = document.createElement('a');
                link.href = doc;
                link.download = gridRow.description;
                link.target = "_blank";
                document.body.appendChild(link);
                link.click()
                URL.revokeObjectURL(link.href)
            },
            documentDataBound: function (e) {
                var self = this;

                var grid = e.sender;
                var items = e.sender.items();

                if (this.ntype == "notary") {
                    grid.element.find(".k-grid-Edit").hide();
                    grid.element.find(".k-grid-Delete").hide();
                    grid.element.find(".k-grid-Download").hide();
                    this.hideCol = true;
                }

                items.each(function (index) {
                    var dataItem = grid.dataItem(this);
                    var row = $("tr[data-uid='" + dataItem.uid + "']");
                    if (!self.canEdit) {
                        row.find(".k-grid-Edit").remove();
                    }
                    if (!self.canDelete) {
                        row.find(".k-grid-Delete").remove();
                    }

                    $("input[class='wetSignCheckbox']", $("tr[data-uid='" + dataItem.uid + "']")).click(function (e) {
                        var isChecked = $("input[class='wetSignCheckbox']", $("tr[data-uid='" + dataItem.uid + "']")).is(":checked");
                        return self.checkboxClicked(e, isChecked);
                    });
                    $("input[class='preSignCheckbox']", $("tr[data-uid='" + dataItem.uid + "']")).click(function (e) {
                        var isChecked = $("input[class='preSignCheckbox']", $("tr[data-uid='" + dataItem.uid + "']")).is(":checked");
                        return self.checkboxClicked(e, isChecked);
                    });
                });

                if (!this.canViewDetails) return;
                $(".package-document-name.link").on("click", function (e) {
                    e.preventDefault();
                    self.$router.push('/admin/document/' + $(this).attr("data-id"));
                });

                this.reloadParent();
            },
            documentDownloadAll() {
                return ApiService.GeneratePostBlobRequest(
                    `document/downloadAll`, { "PackageID": this.packageide })
                    .then((blob) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `package-documents-${moment(Date.now()).format('MM/DD/YYYY')}.zip`;
                        a.click();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            documentUploaded() {
                return ApiService.GeneratePostBlobRequest(
                    `document/uploaded`, { "PackageID": this.packageide })
                    .then((blob) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `uploaded-package-documents-${moment(Date.now()).format('YYYY_MM_DD')}.zip`;
                        a.click();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            documentDownloadAllWetSign() {
                return ApiService.GeneratePostBlobRequest(
                    `document/downloadAllWetSign`, { "PackageID": this.packageide })
                    .then((blob) => {
                        var a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blob);
                        a.download = `wet-sign-package-documents-${moment(Date.now()).format('MM/DD/YYYY')}.zip`;
                        a.click();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            statusTemplate(e) {
                var html = `<div class="status ` + e.status.toLowerCase() + `" ` + this.$options.__scopeId + `><span>` + GridDataHelper.normalizeStatus(e.status) + `</span></div>`;
                return html;
            },
            normalizeDocumentType(e) {
                return GridDataHelper.normalizeStatus(e.documentType)
            },
            parameterMap: function (options, operation) {
                if (operation !== 'read' && options.models) {
                    return { models: kendo.stringify(options.models) }
                }
            },
            readData: function (e) {
                if (!e.data) {
                    e.data = {};
                }
                e.data.LoanID = this.$route.params.id == undefined ? 0 : parseInt(this.$route.params.id);
                e.data.PackageId = parseInt(this.packageide);
                e.data.filter = this.fetchFilter;
                return ApiService.GeneratePostRequest(this.fetchUrl, e.data).then(
                    response => {
                        e.success(response);
                        this.documentCount = response.data.length
                        console.log("Package Documents:", response)
                    }
                );
            },
            createData: function (e) {
                if (!this.canAdd) return;
                // for some odd reason kendo placing form data in array investigate -

                console.log("E: ", e);

                let file = $("#CreateDocumentGridFile")[0].files[0];
                let fileDesc = $("#Description")[0].value;

                let formData = new FormData();
                formData.append("LoanPackageId", this.packageide); // 1044);
                formData.append("File", file);
                formData.append("FileName", file.name);
                formData.append("Description", fileDesc);
                if(this.addText === "Upload" ){
                    formData.append("documentStatusId", 8);
                }
                console.log(file)
                if (!['application/pdf'].includes(file.type)) {
                    Swal.fire({
                        title: 'Unsupported file type',
                        text: "Only PDF files supported",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                    return;
                }

                return ApiService.GeneratePostRequest("package/document/upload", formData, { "Content-Type": "multipart/form-data" }).then(
                    response => {
                        ///e.success(response["content"]); - look at line 409
                        if (response["isSuccess"]) {

                            // we meed to manually refresh the grid because if the inputs on the form does not match
                            // back the columns - kendo won't update the columns i.e only description column will be updated.
                            //one solution is to create hidden inputs and change their values when the return data from the server reaches
                            // but can get tedious quickly.
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data().shift();
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data().unshift(response["content"]);
                            this.$refs.kendoJqueryGrid.kendoWidget().refresh();

                            let documentId = response["content"]["documentID"];
                            // add or remove participants
                            let data = {
                                "documentId": documentId,
                                "participantId": 0,
                                "adminUserId": this.$store.state.Admin.loginInformation.userId,
                            };

                            if (this.participantsToAddForDocuments.length > 0) {
                                this.participantsToAddForDocuments.forEach((id) => {
                                    data.participantId = id;
                                    ApiService.GeneratePostRequest(`package/document/participants/add`, data).then((response) => {
                                        if (response["isSuccess"]) {
                                            this.existingParticipantsForDocument = response["content"];
                                            if (response["content"].length > 0) {
                                                response["content"].forEach((part) => {
                                                    $(`#DocumentsGrid .right-grid #${part.participantId}`).prop("checked", true);
                                                });
                                            }
                                            Swal.fire(
                                                'Document and Participants added',
                                                '',
                                                'success'
                                            );
                                            this.$emit('readLog', this.packageide);
                                        } else {
                                            Swal.fire(
                                                'Document Added. There was an error adding partcipants.',
                                                '',
                                                'error'
                                            );
                                        }
                                    });
                                });
                            }

                            this.currentRecordEditing = null;
                            this.participantsToAddForDocuments = [];

                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.sync();
                            this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read();
                            this.documentCount = this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data().length;
                        } else {
                            Swal.fire(
                                'Operation failed',
                                response.message,
                                'error'
                            );
                        }

                    }
                );
            },
            updateData: function (e) {
                if (!this.canEdit) return;
                console.log("updating", e);
                //let file = $("#CreateDocumentGridFile")[0].files[0];
                let formData = new FormData();
                formData.append("LoanPackageId", this.packageide);  // 1044);
                formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);
                //formData.append("File", file);
                //formData.append("FileName", file.name);description
                let documentId = e.data.models[0]["documentID"];
                formData.append("DocumentId", documentId);
                formData.append("Description", e.data.models[0]["description"]);

                // grid doesn't update data automatically investigate - alternative
                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data().map((item) => {
                    if (item["documentID"] === e.data.models[0]["documentID"])
                        item["description"] = e.data.models[0]["description"];

                    return item;
                });
                this.$refs.kendoJqueryGrid.kendoWidget().refresh();
                return ApiService.GeneratePostRequest("package/document/update", formData, { "Content-Type": "multipart/form-data" }).then(
                    response => {
                        e.success(response["content"]);
                        if (response["isSuccess"]) {

                            // add or remove participants
                            let data = {
                                "documentId": documentId,
                                "participantId": 0,
                                "adminUserId": this.$store.state.Admin.loginInformation.userId
                            };

                            if (this.participantsToRemoveForDOcuments.length > 0) {
                                this.participantsToRemoveForDOcuments.forEach((id) => {
                                    data.participantId = id;
                                    ApiService.GeneratePostRequest(`package/document/participants/remove`, data).then((response) => {
                                        if (response.isSuccess) {
                                            Swal.fire(
                                                'Document updated',
                                                '',
                                                'success'
                                            );
                                            this.$emit('readLog', this.packageide);
                                        } else {
                                            Swal.fire(
                                                'Error',
                                                response.message,
                                                'error'
                                            );
                                        }
                                    });
                                });
                            } else if (this.participantsToAddForDocuments.length > 0) {
                                this.participantsToAddForDocuments.forEach((id) => {
                                    data.participantId = id;
                                    ApiService.GeneratePostRequest(`package/document/participants/add`, data).then(() => {
                                        if (response.isSuccess) {
                                            Swal.fire(
                                                'Document updated',
                                                '',
                                                'success'
                                            );
                                            this.$emit('readLog', this.packageide);
                                        } else {
                                            Swal.fire(
                                                'Error',
                                                response.message,
                                                'error'
                                            );
                                        }
                                    });
                                });
                            } else {
                                Swal.fire(
                                    'Document updated',
                                    '',
                                    'success'
                                );
                                this.$emit('readLog', this.packageide);
                            }

                            this.currentRecordEditing = null;
                            this.participantsToRemoveForDOcuments = [];
                            this.participantsToAddForDocuments = [];

                        } else {
                            Swal.fire(
                                'Update Failed',
                                'Document could not be updated',
                                'error'
                            );
                        }



                    }
                );

            },
            deleteData: function (e) {
                if (!this.canDelete) return;
                let formData = new FormData();
                console.log("DocumentID: " + e.data.models[0]["documentID"]);
                formData.append("DocumentId", e.data.models[0]["documentID"]); // 1044);
                formData.append("AdminUserId", this.$store.state.Admin.loginInformation.userId);
                return ApiService.GeneratePostRequest("package/document/delete", formData, { "Content-Type": "multipart/form-data" }).then(
                    response => {
                        e.success(response);
                        if (response["isSuccess"]) {
                            Swal.fire(
                                'Success',
                                'Document deleted sucessfully',
                                'success'
                            );
                            this.$emit('readLog', this.packageide);
                            this.documentCount = this.$refs.kendoJqueryGrid.kendoWidget().dataSource.data().length;
                        } else {
                            this.$refs.kendoJqueryGrid.kendoWidget().cancelChanges();
                            Swal.fire(
                                'Operation failed',
                                'Document could not be deleted',
                                'error'
                            );
                        }
                    }
                );
            },
            async onEdit(e) {
                if (!this.canEdit) return;
                this.participantsToAddForDocuments = [];
                if (e.model.isNew()) {
                    $("#CreateDocumentGridFile")[0].required = true;
                    $(".k-window-title").text("Add");
                }
                this.participantsToRemoveForDOcuments = [];

                // handles resizing the size of the edit form container
                $(".k-edit-form-container").parent().width(1000).data("kendoWindow").title("Add").center();
                $(".k-edit-form-container").css('width', 'auto');

                if (!e.model.isNew()) {
                    $("#CreateDocumentGridFile")[0].required = false;
                    $(".k-edit-form-container [name='Document']").hide();
                    $(".k-edit-form-container label[for='Document']").parent().hide();
                    $(".k-window-title").text("Edit");
                }

                // every time we add or delete fetch the list of documents so we have the latest
                let response = await ApiService.GenerateGetRequest(`package/${this.packageide}/participants`);

                var assignable = this.canAssign ?? false;

                if (response["isSuccess"]) {
                    // generate html to insert
                    let html = "";
                    response["content"].forEach(function (item) {
                        let fullName = item.firstName + " " + item.lastName;
                        html += "<li class='list-group-item'>";
                        html += KendoPopupGenerator.generatePopUpFormCheckboxFields(item.participantId, fullName, item.participantId, item.participantId, !assignable);
                        html += "</li>";
                    });
                    $("#DocumentsGrid .right-grid ul").html(html);
                    if (e.model.isNew()) {
                        response["content"].forEach((part) => {
                            $(`#DocumentsGrid .right-grid #${part.participantId}`).prop("checked", true);
                            this.participantsToAddForDocuments.push(part.participantId);
                        });
                    }
                }

                if (!e.model.isNew()) {
                    // fetch packages for participant and update checkboxes
                    response = await ApiService.GenerateGetRequest(`package/document/${e.model.documentID}/participants`);

                    if (response["isSuccess"]) {
                        this.existingParticipantsForDocument = response["content"];
                        response["content"].forEach((part) => {
                            $(`#DocumentsGrid .right-grid #${part.participantId}`).prop("checked", true);
                        });
                    }
                }

                let self = this;
                // attach listener to determine which documents to remove and add when admin submits
                $('#DocumentsGrid').find(".right-grid .Toggle-Switch input").on("change", function () {
                    console.log("found");
                    e.model.dirty = true;
                    let docId = parseInt($(this).attr("data-id"));

                    // check if document actioned on is already in existing list
                    let doc = self.existingParticipantsForDocument.find((document) => {
                        return document.participantId === docId;
                    });

                    if (doc) {
                        if ($(this).prop("checked") && self.participantsToRemoveForDOcuments.includes(docId)) {
                            self.participantsToRemoveForDOcuments = self.participantsToRemoveForDOcuments.filter(item => item !== docId)
                        } else if (!$(this).prop("checked") && !self.participantsToRemoveForDOcuments.includes(docId)) {
                            self.participantsToRemoveForDOcuments.push(docId);
                        }
                    } else {
                        if ($(this).prop("checked") && !self.participantsToAddForDocuments.includes(docId)) {
                            self.participantsToAddForDocuments.push(docId);
                        } else if (!$(this).prop("checked") && self.participantsToAddForDocuments.includes(docId)) {
                            self.participantsToAddForDocuments = self.participantsToAddForDocuments.filter(item => item !== docId)
                        }
                    }

                });
            },
            async submitUploadedDocuments(){
                Swal.fire({
                    title: 'Please Confirm All Required Documents Have Been Uploaded',
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Confirm'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        await ApiService.GeneratePostRequest(`package/notary/acknowledge_upload?packageId=${this.packageide}`).then((response) => {
                            if(response.isSuccess){
                                this.$router.go()
                            }else{
                                Swal.fire({
                                    title: 'Error',
                                    text: 'Fail to submit document for closing',
                                    icon: 'error',
                                    confirmButtonText: 'Ok'
                                });
                            }
                        });
                    }
                })
            },
            exportExcel() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };

                        saveExcel({
                            data: process(arr.data, options).data,
                            fileName: "packages",
                            columns: self.columns
                        });
                    }
                });
            },
            exportPDF() {
                var self = this;
                this.readData({
                    success: function (arr) {
                        var options = {
                            skip: 0,
                        };
                        const itemsToExport = process(arr, options).data;
                        (self.$refs.gridPdfExport).save(itemsToExport);
                        return arr;
                    }
                });
            },
            filterChange() {

            },
            onSelectionChange() {

            },
            onHeaderSelectionChange() {

            },
            reloadGrid() {
                this.$refs.kendoJqueryGrid.kendoWidget().dataSource.read()
            }
        },
    }
</script>